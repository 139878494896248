#privacy-policy {
  margin: 0 auto;
  max-width: 600px;
}

h1 {
  text-align: center;
}

@media (max-width: 768px) {
  #privacy-policy {
    margin: 0 40px;
  }

  h1 {
    font-size: 32px;
  }
}
