.App {
  text-align: center;
  background-color: #ffaa02;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: auto;
}

.section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  flex-direction: column;

  h1 {
    font-size: 60px;
  }

  p {
    font-size: 20px;
    margin: 20px 120px;
    line-height: 1.5em;
    font-weight: 400;
  }
}

.button-group {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

button {
  margin: 0 10px;
  padding: 10px;
  font-size: 16px;
  font-style: underline;
  border: none;
  border-radius: 5px;
  background-color: #59a6f2;
  color: #ffffff;
  cursor: pointer;
}

.mockup {
  height: 600px;
}

a {
  color: inherit;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .section {
    margin-bottom: 20px;
    justify-content: flex-start;
    flex: unset;
    align-content: flex-start;
  }

  .description {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin: 0 24px;
      margin-bottom: 10px;
      line-height: 1.5em;
      font-weight: 400;
    }
  }

  .mockup-parent {
    justify-content: center;

    .mockup {
      height: 500px;
    }
  }
}
