#error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

#error-page p {
  font-style: italic;
  font-size: 20px;
  color: #6c757d;
}
